import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import MyLink from "../src/components/MyLink";

const Custom404 = () => (
  <Grid
    container
    style={{ height: "100vh" }}
    alignItems="center"
    justifyContent="center"
  >
    <Grid item>
      <Paper
        style={{ padding: "50px 30px", maxWidth: "70%", margin: "0 auto" }}
      >
        <div>
          <img
            src={process.env.NEXT_PUBLIC_WEBSITE_LOGO_PATH}
            style={{
              width: "25%",
              maxWidth: "160px",
              margin: "0 auto 30px",
              display: "block",
            }}
          />
        </div>
        <Typography variant="h1" align="center">
          404 Page Not Found!
        </Typography>
        <Typography variant="h4" align="center">
          Kindly contact administrator for this issue. Thanks.
        </Typography>
        <MyLink href="/">
          <Typography
            variant="body1"
            align="center"
            style={{ marginTop: "20px" }}
          >{`<< Back To Home`}</Typography>
        </MyLink>
      </Paper>
    </Grid>
  </Grid>
);

export default Custom404;
